import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from "react";
import Calendar from 'react-calendar';
import { ContextRegister } from "../../context";
import Swal from "sweetalert2";
import { AuthFetchJson, url } from "../../api";
import { Header } from "../../components/header";
import { Modal } from "../../components/modal";
import { CheckoutPagamento } from "../checkout-pagamento";

type AgendamentoType = {
    CLIENTE: string,
    LOJA: "01",
    VALOR: number,
    PRECO: {
        METRAGEM: string|number
        TXCC : number, 
        TXSERV: number,
        TABELA: string,
        CEP: string,
        ASSIST: string,
        DTAGEND: string,
        TURNO: "M"|"T"|"N",
        DTRETOR: string,
        TURNORE: "M"|"T"|"N"|"",
        ENDER: string,
        NUMERO: number,
        COMPLE: string,
        BAIRRO: string,
        EST: string,
        MUN: string,
        FPGTO: "P"|"C",
        TERMO: "S",
        ITENS: 
            {
                ITEM: string,
                QUANT: number,
                VRUNIT: number
            }[]
        
    }
}

type DataPiece = Date | null;
type AgendaType = DataPiece | [DataPiece, DataPiece];

export function ProtegerFormulario() {

    const now = new Date()
    const navigate = useNavigate();
    const [data, setData] = useState<AgendaType>(now);
    const [dataRetorno, setDataRetorno] = useState<AgendaType>(now);
    const { state, dispatch} = useContext(ContextRegister);
    const currencyFormater =  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    const [turno, setTurno] = useState<"M"|"T"|"N">("M")
    const [turnoRetorno, setTurnoRetorno] = useState<"M"|"T"|"N">("M")
    const [tipoPagamento, setTipoPagamento] = useState<"P"|"C">("C")

    const [termo, setTermo] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    const [showModalRetorno, setShowModalRetorno] = useState(false)


    function proxyHandleNext(event: FormEvent) {
        event.preventDefault()
        
        const containReturnService =  state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP02" && item.QUANT > 1).length > 0
        let dataAux: Date = data as Date

        if(dataAux < now) {

            Swal.fire("Data de agendamento deve ser maior que data atual.")
            return;
        }

        if(containReturnService) {
                setShowModalRetorno(true);
                return;
        }

        handleNext()

    }

    async function handleNext() {

        if(!data) {
            
            Swal.fire({
                title: "Selecione a Data para  o serviço!",
                icon: "error",
                timer: 1500
            })
            
            return
        }

        const containReturnService =  state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP02" && item.QUANT > 1).length > 0
        let dataAux: Date = data as Date

        if(containReturnService) {

            
            let dataRetornoAux: Date = dataRetorno as Date

            let dataAuxTwoWeeks = new Date(`${dataAux.getFullYear()}-${dataAux.getMonth()+1}-${dataAux.getDate()} 00:00`)
            let dataAuxTwoMonths = new Date(`${dataAux.getFullYear()}-${dataAux.getMonth()+1}-${dataAux.getDate()} 00:00`)

            //verifica se a data tem uma diferença maior que 2 semanas ou maior que 2 meses

            dataAuxTwoWeeks.setDate(dataAux.getDate() + 15)
            dataAuxTwoMonths.setDate(dataAux.getDate() + 20)

            if(dataAuxTwoWeeks > dataRetornoAux || dataAuxTwoMonths < dataRetornoAux) {
                Swal.fire("Datas de agendamento e retorno incorretas.")
                return;
            }

        }

        const formatedDate = data.toLocaleString('pt-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).split('/').reverse().join('') 


        let formatedDateRetorno = ""

        if(dataRetorno && true) {
            formatedDateRetorno = dataRetorno.toLocaleString('pt-BR', {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).split('/').reverse().join('')   
        }

        let agendamento: AgendamentoType = {
            CLIENTE: state.usuario.A1_COD,
            LOJA: state.usuario.A1_LOJA,
            VALOR: state.formacaoPreco.VALOR,
            PRECO: {
                METRAGEM: parseInt(state.formacaoPreco.METRAGEM)||0,
                TXCC : state.formacaoPreco.TXCC, 
                TXSERV: state.formacaoPreco.TXSERV,
                TABELA: state.agendamento.tabela,
                CEP: state.agendamento.CEP,
                ASSIST: `${state.formacaoPreco.ASSISDIAS}`,
                DTAGEND: formatedDate,
                TURNO: turno,
                DTRETOR: containReturnService? formatedDateRetorno : "",
                TURNORE: containReturnService?  turnoRetorno: "",
                ENDER: state.agendamento.endereco,
                NUMERO: parseInt(state.agendamento.numero)||0,
                COMPLE: state.agendamento.complemento,
                BAIRRO: state.agendamento.bairro,
                EST: state.agendamento.estado,
                MUN: state.agendamento.municipio,
                FPGTO: tipoPagamento,
                TERMO: "S",
                ITENS: state.formacaoPreco.ITENS.map(item => ({
                    ITEM: item.ITEM,
                    QUANT: item.QUANT,
                    VRUNIT: item.VRUNIT
                }))
            }
        } 

        Swal.fire({
            title: 'Aguarde!',
            html: 'Salvando seu pedido.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        AuthFetchJson(`${url}INSEREPEDIDO`, {
            method: 'POST',
            body: JSON.stringify(agendamento)
        })
        .then( async (retorno: any) => {

            if(retorno.sucesso !== 'T') {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao gerar seu pedido, " + retorno.mensagem,
                });
                return
            }   

            
            /* AuthFetchJson(`${url}FATURAPEDIDO`, {
                method: 'POST',
                body: JSON.stringify({
                    NUMPV: retorno.dados[0].C5_NUM
                })
            }).then(console.log) */
            

            await AuthFetchJson(`${url}CHECKOUTPAGAMENTO`, {
                method: "POST",
                body: JSON.stringify({
                    "reference_id": retorno.dados[0].C5_NUM,
                    "customer_modifiable": true,
                    "items": [
                    {
                        "reference_id": "0001",
                        "name": "Dedetizacao Ipest",
                        "description": "Dedetizacao Ipest",
                        "quantity": 1,
                        "unit_amount": state.formacaoPreco.VALOR*100,
                        "image_url": "https://ipest.com.br/wp-content/uploads/2023/12/IMGDESTACADA.png"
                    }
                    ],
                    "payment_methods": [
                        tipoPagamento === 'C' ?
                    {
                        "type": "CREDIT_CARD"
                    } :
                    {
                        "type": "PIX"
                    }
                    ],
                    "soft_descriptor": "Ipest dedetizacao",
                    "redirect_url": "https://portal.ipest.com.br/servicos",
                    "notification_urls": [
                    "HTTPS://API.IPEST.COM.BR/REST/SOIPESWS/FATURAPEDIDO"
                    ]
                })

            }).then((retorno) => {

                if(retorno?.sucesso === 'T') {

                    window.open(retorno?.dados[0]?.links[1].href)
                }

                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao gerar pagamento, tente novamente ou entre em contato!",
                    });
                }

            })

            

            })
        .catch(error => {

            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao gerar seu pedido, tente novamente ou entre em contato!",
            });
        }).finally(() => {

            if(Swal.isLoading()) {

                Swal.close()
                navigate('/finalizado')
            }
        })

    }

    function updateState(event: ChangeEvent<HTMLInputElement>) {

        let aux: any = { ...state.agendamento }
        aux[event.target.name] = event.target.value


        dispatch({ 
            type: 'agendamento',
            payload: aux
        })
        
    }

    async function handlePagamento(tipo: "C"|"P") {

        setTipoPagamento(tipo)

        Swal.fire({
            title: 'Aguarde!',
            html: 'Calculando valor do serviço.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });        

        let body = {

            TABELA: state.formacaoPreco.TABELA,
            ASSDIAS: state.formacaoPreco.ASSISDIAS,
            ITENS: state.formacaoPreco.ITENS.map(item => item.ITEM),
            FPGTO: tipo
        }


        let response: any  = await AuthFetchJson(url+'COLETAPRECO', {
            body: JSON.stringify(body),
            method: 'POST'
        })

        if(response.sucesso !== 'T') {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao gerar preço, tente novamente ou entre em contato com o suporte!",
            });
            return
        }


        


        if(Swal.isLoading()) {
            Swal.close()
        }

        response.dados[0]['ASSISDIAS'] = state.formacaoPreco.ASSISDIAS
        response.dados[0]['TABELA'] = state.formacaoPreco.TABELA
        response.dados[0]['METRAGEM'] = state.formacaoPreco.METRAGEM
        dispatch({
            type: 'formacaoPreco',
            payload: response.dados[0]
        })
    }

    useEffect(() => {
        if(!state?.formacaoPreco?.VALOR && state?.formacaoPreco?.VALOR === 0) {
            navigate("/home")
        }
    }, [])

    return (

        <div className={styles.container}>

            <Header />
            {
                showModalRetorno &&
                <Modal 
                    handleNext={handleNext}
                    footer={true} 
                    setShowModal={setShowModalRetorno} 
                    title="Dados de Retorno">
                    <p>Algumas pragas precisam de duas visitas para serem completamente exterminadas. Adicione abaixo a data de sua preferência para a segunda visita do seu atendimento. A data deve estar entre 15 e 20 dias após a primeira visita.
                    </p>
                        <br/>
                    <div style={{ maxWidth: "450px" }}>
                        <Calendar locale="pt-br" onChange={setDataRetorno} value={dataRetorno} />
                    </div>
                    <div className={styles.radioGroupContainer2}>
                        <div className={styles.radioGroup}>
                            <input title="periodo" onChange={ _ => setTurnoRetorno("M")} type="radio" value="M" checked={turnoRetorno==="M"} required/>
                            <span>&nbsp;Manhã(8h-12h)</span>
                        </div>
                        <div className={styles.radioGroup}>
                            <input title="periodo" onChange={event => setTurnoRetorno("T")} type="radio" value="T" checked={turnoRetorno==="T"} required/>
                            <span>&nbsp;Tarde(13h-17h)</span>
                        </div>
                        <div className={styles.radioGroup}>
                            <input title="periodo" onChange={event => setTurnoRetorno("N")} type="radio" value="N" checked={turnoRetorno==="N"} required/>
                            <span>&nbsp;Noite(18h-22h)</span>
                        </div>

                    </div>
                </Modal>
            }

            <div className={styles.content} >
                <form onSubmit={proxyHandleNext} ref={formRef}>

                <div className={styles.cardForm}>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Agendamento do serviço</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.calendario}>
                            <Calendar locale="pt-br" onChange={setData} value={data} />
                        </div>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Agendamento do serviço</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>
                            <div className={styles.radioGroup}>
                                <input title="periodo" onChange={ _ => setTurno("M")} type="radio" name="periodo" value="M" checked={turno==="M"} required/>
                                <span>&nbsp;Manhã (8h - 12h)</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input title="periodo" onChange={event => setTurno("T")} type="radio" name="periodo" value="T" checked={turno==="T"} required/>
                                <span>&nbsp;Tarde (13h - 17h)</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input title="periodo" onChange={event => setTurno("N")} type="radio" name="periodo" value="N" checked={turno==="N"} required/>
                                <span>&nbsp;Noite (18h - 22h)</span>
                            </div>

                        </div>
                    </div>

                    <hr />


                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Endereço para execução</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.groupInput}>
                            <span>CEP <RxDotFilled className={styles.legend} /></span>
                            <input disabled={true} onChange={updateState}  value={state.agendamento.CEP}  type="text" name="cep" placeholder="00.000-000" required/>
                            <label>digite apenas números</label>
                        </div>

                        <div className={styles.groupInput}>
                            <span>Endereço <RxDotFilled className={styles.legend} /></span>
                            <input title="endereco" value={state.agendamento.endereco} onChange={updateState} type="text" name="endereco" placeholder="" required/>
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Número <RxDotFilled className={styles.legend} /></span>
                                <input title="numero" type="text" name="numero" placeholder="" onChange={updateState} required/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                            <div className={styles.groupInput}>
                                <span>Complemento </span>
                                <input title="complemento" type="text" name="complemento" placeholder=""  onChange={updateState}/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Estado <RxDotFilled className={styles.legend} /></span>
                                <input title="estado" value={state.agendamento.estado} type="text" name="estado" placeholder="" onChange={updateState} required/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                            <div className={styles.groupInput}>
                                <span>Município <RxDotFilled className={styles.legend} /></span>
                                <input title="municipio" value={state.agendamento.municipio} type="text" name="municipio" placeholder="" onChange={updateState} required/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                        </div>
                    </div>

                    <div className={styles.groupCardFormPG}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Forma de pagamento</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupPagamento}>
                            <div className={styles.radioGroup}>
                                <input title="tipo pagamento" type="radio" name="tipoPagamento" value={"C"} onChange={() => handlePagamento("C")} checked={tipoPagamento==="C"} required/>
                                <span>&nbsp;Cartão de crédito</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input title="tipo pagamento" type="radio" name="tipoPagamento" value={"P"} onChange={() => handlePagamento("P")} checked={tipoPagamento==="P"} required/>
                                <span>&nbsp;PIX <span>(5% de desconto)</span></span>
                            </div>
                        </div>

                        <div className={styles.valorServico}>

                            <h3>Valor:</h3> 
                            <p>{currencyFormater.format(state.formacaoPreco.VALOR)}</p>
                        </div>
                    </div>

                    <div className={styles.termoContainer}>
                        <div className={styles.radioGroup}>
                            <input title="termo" type="radio" name="termo" onChange={() => setTermo(t=> !t)}  checked={termo} required/>
                            <span>&nbsp;Eu aceito os Termos de Uso e Políticas de Privacidade.</span>
                        </div>
                    </div>
                    
                    <div className={formRef?.current?.checkValidity() ? styles.submitNextSelected : styles.submitNext}>
                        <button>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>
                </form>
            </div>
        </div>

    )

}