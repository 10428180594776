import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import avisoPrivacidade from "../../assets/privacidade.pdf"
import termosUso from "../../assets/uso.pdf"

import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, createContext, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { clienteType, clienteDefault } from "../../context/cliente-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";

export const FormularioContext = createContext<{formulario: clienteType, setFormulario: Dispatch<SetStateAction<clienteType>>}| null>(null)

export function CadastroFormulario() {
    const navigate = useNavigate();

    const  [tab, setTab] = useState<'dadosbasicos'|'endereco'|'contato'|'observacao'>('dadosbasicos')

    const [formulario, setFormulario] = useState(clienteDefault);

    const [termo, setTermo] = useState(false);


    function selectedTab() {

        switch(tab) {

            case 'dadosbasicos':
                return <DadosBasicos/>
            case 'endereco':
                return <Endereco/>
            case 'contato':
                return <Contato/>
            default: 
                return <DadosBasicos/>

        }

    }

    function handleNext() {

        if(tab === "dadosbasicos") {
            setTab("endereco") 
            return
        }

        if(tab === "endereco") {
            setTab("contato") 
            return
        }
        
        if(tab === "contato") {

            if(!termo) {
                Swal.fire("Necessário Aceitar os Termos de Uso e Políticas de Privacidade.")
                return;
            }
     


            let aux = {...formulario}

            aux.A1_DTNASC = aux.A1_DTNASC?.replaceAll('-', '')
            aux.A1_CGC = aux.A1_CGC?.replaceAll('-', '')
            aux.A1_CGC = aux.A1_CGC?.replaceAll('.', '')
            aux.A1_NREDUZ = aux.A1_NOME.substring(0, 20)

            Swal.fire({
                title: 'Aguarde!',
                html: 'Cadastro de usuário!',// add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });


            AuthFetchJson(url+ 'INSERECLIENTE', {
                body: JSON.stringify( { dados: aux } ),
                method: 'POST'
            })
            .then( (retorno: any) => {
                if(retorno.sucesso === 'T') {

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Cadastro realizado com sucesso!",
                        showConfirmButton: false,
                        timer: 1500
                      });
                    navigate('/')

                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao cadastrar cliente, " + retorno.mensagem,
                        showCancelButton: false
                    });
                }


            })
            .catch((error) => {

                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao cadastrar cliente!" + error.message,
                    showConfirmButton: false,
                });
            }).finally(() => {
                if(Swal.isLoading()) {
                    Swal.close()
                }
            })

        }

    }

    function openTerm() {

    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <img alt="logo" src={logo} className={styles.logo}/>
                <h1 className={styles.title}>Cadastro</h1>
            </div>

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>


            <div className={styles.content} >

                





                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p 
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p 
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p 
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{formulario, setFormulario}}>
                    {
                        selectedTab()
                    }
                    </FormularioContext.Provider>
                
                    <hr />
                    
                    { tab === "contato" &&
                    <div className={styles.termoContainer}>
                        <div className={styles.radioGroup}>
                            <input title="termo" type="radio" name="termo" onClick={() => setTermo(!termo)} checked={termo} />
                            <span> Eu aceito os </span>
                            <a rel="noreferrer" href={termosUso} target="_blank">Termos de Uso</a>
                            <span> e </span>
                            <a rel="noreferrer" href={avisoPrivacidade} target="_blank">Políticas de Privacidade.</a>
                        </div>
                    </div>
                     }

                    
                    <div className={termo && tab === "contato" ? styles.submitNextSelected : styles.submitNext}>
                        <button type="button" onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}